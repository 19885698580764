import PropTypes from 'prop-types';
import React, { memo, useCallback, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Form } from 'react-bootstrap';

import { changeGroup } from '../../../../../../actions';

import CheckBox from '../../../../../UI/CheckBox/CheckBox';
import Preloader from '../../../../../UI/Preloader/Preloader';

import TagsInput from '../../../../../Includes/TagsInput/TagsInput';

const GroupsGroupSettings = ({
  data, isUpdating, onUpdateGroup,
}) => {
  const dispatch = useDispatch();

  const displays = useRef({
    in_menu: 'Меню',
    in_popular: 'Популярные',
    via_link: 'Доступно по ссылке',
  });

  const handleChangeGroup = useCallback((changedData) => {
    dispatch(changeGroup(data._id, changedData));
  }, [dispatch, data._id]);

  const handleUpdateGroup = useCallback(() => {
    onUpdateGroup(data);
  }, [onUpdateGroup, data]);

  return (
    <>
      <div className="template-bunch-item__form-row template-bunch-item__form-short-title">
        <div className="template-bunch-item__form-label">Короткое название:</div>
        <Form.Control
          disabled={isUpdating}
          value={data.short_title}
          onChange={(e) => handleChangeGroup({ short_title: e.target.value })}
        />
      </div>
      <div className="template-bunch-item__form-row">
        <div className="template-bunch-item__form-label">Алиас:</div>
        <Form.Control
          disabled={isUpdating}
          value={data.slug}
          onChange={(e) => handleChangeGroup({ slug: e.target.value })}
        />
      </div>
      <div className="template-bunch-item__form-row">
        <div className="template-bunch-item__form-label">Название:</div>
        <Form.Control
          disabled={isUpdating}
          value={data.title}
          onChange={(e) => handleChangeGroup({ title: e.target.value })}
        />
      </div>
      <div className="template-bunch-item__form-row">
        <div className="template-bunch-item__form-label">Отображение:</div>
        {Object.keys(displays.current).map((display) => (
          <CheckBox
            checked={data[display]}
            disabled={isUpdating}
            id={display + data._id}
            key={display + data._id}
            onChange={(value) => {
              handleChangeGroup({ [display]: value });
            }}
          >
            {displays.current[display]}
          </CheckBox>
        ))}
      </div>
      <div className="template-bunch-item__form-row">
        <div className="template-bunch-item__form-label">SEO Title:</div>
        <Form.Control
          disabled={isUpdating}
          value={data?.seo?.title || ''}
          onChange={(e) => handleChangeGroup({ seo: { ...data.seo, title: e.target.value } })}
        />
      </div>
      <div className="template-bunch-item__form-row">
        <div className="template-bunch-item__form-label">SEO Description:</div>
        <Form.Control
          disabled={isUpdating}
          value={data?.seo?.description || ''}
          onChange={(e) => handleChangeGroup({ seo: { ...data.seo, description: e.target.value } })}
        />
      </div>
      <div className="template-bunch-item__form-row">
        <div className="template-bunch-item__form-label">SEO Keywords:</div>
        <Form.Control
          disabled={isUpdating}
          value={data?.seo?.keywords || ''}
          onChange={(e) => handleChangeGroup({ seo: { ...data.seo, keywords: e.target.value } })}
        />
      </div>
      <div className="template-bunch-item__form-row">
        <div className="template-bunch-item__form-label">Теги:</div>
        <TagsInput
          defaultTags={data.tags}
          disabled={isUpdating}
          onChange={(tags) => handleChangeGroup({ tags })}
        />
      </div>
      <div className="template-bunch-item__form-row">
        <Button disabled={isUpdating || !data.isUnsaved} variant="success" onClick={handleUpdateGroup}>
          Сохранить
        </Button>
        {isUpdating && <Preloader medium />}
      </div>
    </>
  );
};

GroupsGroupSettings.propTypes = {
  data: PropTypes.object.isRequired,
  isUpdating: PropTypes.bool,
  onUpdateGroup: PropTypes.func,
};

export default memo(GroupsGroupSettings);
