import PropTypes from 'prop-types';
import React, {
  memo, useEffect, useRef, useState, useMemo,
} from 'react';
import { useDispatch } from 'react-redux';
import { Button, Dropdown, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  addCategoryTemplatesCount,
  addReadyVariation,
  autoCheckVariations,
  autoCheckUpdateVariations,
  changeTemplate,
  createVariation,
  // getCategories,
  removeTemplate,
  removeVariation,
  updateTemplate,
  updateVariation,
  updateReadyVariation,
  setTdrNotice,
  sendTdrNotice,
  removeTdrNotice,
} from '../../../../../actions';

import { variationTypes } from '../../../../../constants/common';

import { requestCanceler } from '../../../../../middlewares/api';

import { toast } from '../../../../../utils';

import sweetAlert from '../../../../HOCs/sweetAlert';

import TagsInput from '../../../../Includes/TagsInput/TagsInput';
import FieldForm from '../../../../Includes/FieldForm/FieldForm';

import CheckBox from '../../../../UI/CheckBox/CheckBox';
import Preloader from '../../../../UI/Preloader/Preloader';

import TdrNoticeModal from './TdrNoticeModal/TdrNoticeModal';

import TemplatesTemplateCategories from './TemplatesTemplateCategories/TemplatesTemplateCategories';
import TemplatesTemplateResources from './TemplatesTemplateResources/TemplatesTemplateResources';
import TemplatesTemplateVariations from './TemplatesTemplateVariations/TemplatesTemplateVariations';
import TemplatesTemplatePreview from './TemplatesTemplatePreview/TemplatesTemplatePreview';

import './TemplatesTemplate.scss';

const tdrTypes = ['tdr_paid', 'tdr_accepted', 'tdr_rejected'];

const defaultVisibleRows = {
  categories: false,
  tdr: false,
  variations: null,
};

const TemplatesTemplate = ({
  categories, data, isTemplateChanging,
}) => {
  const dispatch = useDispatch();
  const requestCancelers = useRef([]);
  const [resetVideoFieldValue, setResetVideoFieldValue] = useState(false);
  const [rowIsVisible, setRowIsVisible] = useState(defaultVisibleRows);
  const [createVariationType, setCreateVariationType] = useState('video');

  useEffect(() => () => requestCancelers.current.forEach((canceler) => canceler.cancelRequest()), []);

  const handleSetRowIsVisible = (props) => setRowIsVisible((prev) => ({ ...prev, ...props }));

  const handleChangeTemplate = (params) => {
    dispatch(changeTemplate(data.id, params));
  };

  const handleUpdateTemplate = () => {
    if (!data.title) {
      toast('error', 'Укажите название шаблона');

      return;
    }

    if (!data.categories.length) {
      toast('error', 'Укажите хотя бы одну категорию шаблона');

      return;
    }

    const requestUpdateTemplateCanceler = requestCanceler();

    requestCancelers.current.push(requestUpdateTemplateCanceler);

    // let allCategories = JSON.parse(JSON.stringify(categories));

    // data.categories.forEach((category) => {
    //   allCategories = allCategories.map((item) => ({
    //     id: item.id,
    //     ...(category.category_id === item.id ? { selected: category.selected } : {}),
    //   }));
    // });

    dispatch(updateTemplate({
      ...data,
      categories: data.categories
        .filter((item) => item.selected)
        .map((item) => ({ category_id: item.category_id })),
    }, requestUpdateTemplateCanceler.token))
      .then(() => handleSetRowIsVisible(defaultVisibleRows))
      .then(() => toast('success', 'Шаблон обновлен'))
      .then(() => {
        if (data?.notifications && data.notifications.length) {
          dispatch(sendTdrNotice({ template_id: data.id, notifications: data.notifications }))
            .then(() => toast('success', 'Пользователю были отправлены уведомления о статусе заявки'));
        }
      });
    // .then(() => dispatch(getCategories({ all: 1, service: 1, lang: data.lang }, false)));
  };

  const handleRemoveTemplate = () => {
    sweetAlert.fire({
      cancelButtonText: 'Нет',
      confirmButtonText: 'Да, удалить',
      icon: <FontAwesomeIcon icon={['fal', 'exclamation-triangle']} />,
      type: 'warning',
      showCancelButton: true,
      title: 'Вы уверены?',
      html: 'Вы действительно хотите удалить шаблон?',
    }).then((result) => {
      if (!result.value) return;

      const requestRemoveTemplateCanceler = requestCanceler();

      requestCancelers.current.push(requestRemoveTemplateCanceler);

      dispatch(removeTemplate(data._id, requestRemoveTemplateCanceler.token))
        .then(() => {
          data.categories.forEach((cat) => {
            dispatch(addCategoryTemplatesCount(cat.category_id, -1));
          });

          toast('success', `Шаблон #${data.id} удалён`);
        });
    });
  };

  const handleToggleCategories = (isAdd, categoryData, type) => {
    if (data.tdr && tdrTypes.includes(type)) {
      if (isAdd) {
        sweetAlert.fire({
          customClasses: {
            popup: 'template__notice-modal',
          },
          showConfirmButton: false,
          allowOutsideClick: false,
          title: 'Оповещение',
          html: (
            <TdrNoticeModal
              template={data}
              type={type}
              onConfirm={(templateId, notification) => {
                dispatch(setTdrNotice(templateId, notification));

                sweetAlert.close();
              }}
            />
          ),
        });
      } else {
        dispatch(removeTdrNotice(data.id, type));
      }
    }

    const selectedCategory = {
      ...categoryData,
      ...(isAdd ? { selected: true } : { selected: false }),
    };

    let finded = null;

    if (isAdd) {
      finded = data.categories.findIndex((cat) => cat.category_id === selectedCategory.category_id);
    }

    handleChangeTemplate(
      isAdd
        ? {
          categories: finded < 0
            ? [...data.categories, selectedCategory]
            // eslint-disable-next-line
            : data.categories.map((cat) => cat.category_id === selectedCategory.category_id ? selectedCategory : cat),
          hasSelectedCategories: true,
        }
        // eslint-disable-next-line
        : { categories: data.categories.map((cat) => cat.category_id === selectedCategory.category_id ? selectedCategory : cat), hasSelectedCategories: true },
    );
  };

  const handleCreateVariation = (videoId) => {
    if (!videoId) return;

    const requestCreateVariationCanceler = requestCanceler();

    requestCancelers.current.push(requestCreateVariationCanceler);

    dispatch(createVariation(data.id, videoId, createVariationType, requestCreateVariationCanceler.token))
      .then((response) => {
        handleSetRowIsVisible({ variations: String(response.id) });

        return dispatch(addReadyVariation(data.id, response));
      })
      .then(() => dispatch(autoCheckVariations(data.id)))
      .then(() => setResetVideoFieldValue(true));
  };

  const handleUpdateVariation = (variationId, variationData) => {
    if (!variationData.video_id) return;

    const requestUpdateVariationCanceler = requestCanceler();

    requestCancelers.current.push(requestUpdateVariationCanceler);

    dispatch(updateVariation(variationId, variationData, requestUpdateVariationCanceler.token))
      .then((response) => dispatch(updateReadyVariation(data.id, variationId, response)))
      .then(() => dispatch(autoCheckUpdateVariations(data.id)));
  };

  const handleRemoveVariation = (variationId) => {
    sweetAlert.fire({
      cancelButtonText: 'Нет',
      confirmButtonText: 'Да, удалить',
      icon: <FontAwesomeIcon icon={['fal', 'exclamation-triangle']} />,
      type: 'warning',
      showCancelButton: true,
      title: 'Вы уверены?',
      html: 'Вы действительно хотите удалить вариацию?',
    }).then((result) => {
      if (!result.value) return;

      const requestRemoveVariationCanceler = requestCanceler();

      requestCancelers.current.push(requestRemoveVariationCanceler);

      dispatch(removeVariation(data.id, variationId, requestRemoveVariationCanceler.token))
        .then(() => toast('success', `Вариация #${variationId} удалена`));
    });
  };

  const mainPreview = useMemo(() => {
    let res = null;

    if (data?.variations && data?.variations?.length) {
      if (!res) res = data.variations[0]?.image;
    }

    return res;
  }, [data.variations]);

  return (
    <div className="template">
      <TemplatesTemplatePreview alt="Превью шаблона" src={mainPreview} />
      <div className="template__content">
        <div className="template__header">
          <div className="template__header-content">
            <Form.Control
              className="template__title"
              value={data.title}
              onChange={(e) => handleChangeTemplate({ title: e.target.value })}
            />
            <CheckBox
              checked={data.in_list}
              id={data._id}
              onChange={(value) => handleChangeTemplate({ in_list: value })}
            >
              Показывать
            </CheckBox>
          </div>
          <div className="template__header-actions">
            {(!!isTemplateChanging.update[data._id] || !!isTemplateChanging.remove[data._id]) && <Preloader medium />}
            <Button
              disabled={!data?.isUnsaved || !!isTemplateChanging.update[data._id] || !!isTemplateChanging.remove[data._id]}
              onClick={handleUpdateTemplate}
            >
              Сохранить
            </Button>
            <Button
              disabled={!!isTemplateChanging.remove[data._id]}
              className="template__remove-btn"
              variant="light"
              size="sm"
              onClick={handleRemoveTemplate}
            >
              <FontAwesomeIcon icon={['fal', 'times']} />
            </Button>
          </div>
        </div>
        <div className="template__row">
          <div className="template__label">ID шаблона:</div>
          <div className="template__row-content template__id">{data.id}</div>
        </div>
        <div className="template__row">
          <div className="template__label">Добавить вариацию:</div>
          <div className="template__row-content">
            <Dropdown className="template__type-select">
              <Dropdown.Toggle
                as="button"
                className="btn btn-light"
                disabled={isTemplateChanging.createVariation}
              >
                {variationTypes[createVariationType]}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {createVariationType !== 'video' && (
                  <Dropdown.Item
                    as="button"
                    eventKey="video"
                    onSelect={(value) => setCreateVariationType(value)}
                  >
                    {variationTypes.video}
                  </Dropdown.Item>
                )}
                {createVariationType !== 'image' && (
                  <Dropdown.Item
                    as="button"
                    eventKey="image"
                    onSelect={(value) => setCreateVariationType(value)}
                  >
                    {variationTypes.image}
                  </Dropdown.Item>
                )}
                {createVariationType !== 'site' && (
                  <Dropdown.Item
                    as="button"
                    eventKey="site"
                    onSelect={(value) => setCreateVariationType(value)}
                  >
                    {variationTypes.site}
                  </Dropdown.Item>
                )}
              </Dropdown.Menu>
            </Dropdown>
            <FieldForm
              buttonText="Добавить"
              disabled={isTemplateChanging.createVariation || !!isTemplateChanging.remove[data._id]}
              fieldType="input"
              placeholder="ID видео"
              resetOn={resetVideoFieldValue}
              onSubmit={handleCreateVariation}
            />
          </div>
        </div>
        <TemplatesTemplateCategories
          allCategories={categories}
          categories={data.categories}
          disabled={!!isTemplateChanging.update[data._id] || !!isTemplateChanging.remove[data._id]}
          isVisible={rowIsVisible.categories}
          toggleIsVisible={() => handleSetRowIsVisible({ categories: !rowIsVisible.categories })}
          templateId={data.id}
          onChange={(isAdd, categoryData, type) => handleToggleCategories(isAdd, categoryData, type)}
        />
        <div className="template__row">
          <div className="template__label">Теги:</div>
          <div className="template__row-content">
            <TagsInput
              defaultTags={data.tags}
              disabled={!!isTemplateChanging.update[data._id] || !!isTemplateChanging.remove[data._id]}
              onChange={(tags) => handleChangeTemplate({ tags })}
            />
          </div>
        </div>
        {data?.notifications && data?.notifications?.length ? (
          <div className="template__row">
            <div className="template__label">Уведомлений:</div>
            <div className="template__row-content template__id">{data.notifications.length}</div>
          </div>
        ) : null}
        {data?.variations?.length && !isTemplateChanging.remove[data._id] ? (
          <TemplatesTemplateVariations
            isTemplateChanging={isTemplateChanging}
            isVisible={rowIsVisible.variations}
            variations={data.variations}
            toggleIsVisible={(val) => handleSetRowIsVisible({ variations: val })}
            onRemoveVariation={handleRemoveVariation}
            onUpdateVariation={handleUpdateVariation}
          />
        ) : null}
        {data?.tdr ? (
          <div className="template__row template__tdr">
            <div className="template__label">
              TDR
              {/* eslint-disable-next-line */}
              <span role="button" onClick={() => handleSetRowIsVisible({ tdr: !rowIsVisible.tdr })}>{rowIsVisible.tdr ? 'скрыть' : 'подробнее'}</span>
            </div>
            {rowIsVisible.tdr ? (
              <div className="template__tdr-content">
                <div className="template__row">
                  <div className="template__label">Отправлено:</div>
                </div>
                <div className="template__row">
                  <div className="template__label">Логин:</div>
                  <div className="template__row-content">
                    <a href={`mailto:${data.tdr.email}`}>{data.tdr.email}</a>
                  </div>
                </div>
                <div className="template__row">
                  <div className="template__label">Платеж через:</div>
                  <div className="template__row-content">
                    {data.tdr.payment?.title}
                  </div>
                </div>
                <div className="template__row">
                  <div className="template__label">Номер кошелька:</div>
                  <div className="template__row-content">
                    {data.tdr.payment?.walletNumber}
                  </div>
                </div>
                <div className="template__row">
                  <div className="template__label">Имя автора:</div>
                  <div className="template__row-content">
                    {data.tdr?.username}
                  </div>
                </div>
                <div className="template__row">
                  <div className="template__label">Комментарий:</div>
                  <div className="template__row-content template__tdr-comment">
                    {data.tdr?.message}
                  </div>
                </div>
                <TemplatesTemplateResources variations={data?.variations} />
              </div>
            ) : null}
          </div>
        ) : null}
      </div>
    </div>
  );
};

TemplatesTemplate.propTypes = {
  categories: PropTypes.array,
  data: PropTypes.object,
  isTemplateChanging: PropTypes.object,
};

export default memo(TemplatesTemplate);
